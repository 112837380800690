import React from "react";

import Layout from "../components/Layout";
import FaqsSection from "../content/FaqsSection";

const Faqs = (props) => {
  return (
    <Layout>
      <FaqsSection isPage />
    </Layout>
  );
};

export default Faqs;
